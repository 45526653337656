<template>
  <div>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <Appointments />
  </div>
</template>

<script>
// @ is an alias to /src
import Appointments from "@/components/Appointments.vue";
import { mapActions } from "vuex";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";

export default {
  mixins: [StorageGetterMixin],
  name: "Home",
  components: {
    Appointments,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({ text: "Home", path: "module.HOME", level: 0 });
    console.log(`%c CREATED HOMEPAGE`, "color: orange");
    /* */
    console.log("created template table");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {
            console.info(`background data "${path}reports" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("insurers"))
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (!this.hasLoadedResource("conti_prima_nota"))
      resources.push(
        this.$store
          .dispatch(`${path}conti_prima_nota`)
          .then(() => {
            console.info(
              `background data "${path}conti_prima_nota" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
    /* */
  },
};
</script>
<style scoped>
.home {
  text-align: center;
}
</style>
